import { Link } from 'gatsby'
import React from 'react'

import '@src/theme/thanks.sass'

const text = [
  'Thank you for contact us!!',
  'We are working hard, but sometime we can’t response fast.',
  'We will response as soon as possible.',
]

const ThanksPage = ({}: any) => (
  <div className="thanksPage">
    <div
      style={{
        textAlign: 'center',
      }}
    >
      {text.map((t) => (
        <p key={t}>{t}</p>
      ))}
    </div>
    <Link to="/" style={{ paddingTop: 30, fontSize: 20 }}>
      ホームへ
    </Link>
  </div>
)

export default ThanksPage
